<template>
  <div class="js-common-wrap js-his-records">
    <div class="js-common-head">
      <label>历史记录</label>
      <div>
        <el-button
          type="primary"
          size="small"
          v-if="getTempraryShow"
          @click="search"
          >搜索</el-button
        >
        <el-radio-group
          class="label"
          v-model="options.mine"
          size="small"
          @change="typeChange"
        >
          <el-radio-button :label="1">我的</el-radio-button>
          <el-radio-button :label="0">全部</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="js-common-content">
      <el-table
        class="js-table"
        :data="tableData"
        height="100%"
        empty-text="暂无数据"
        @row-click="handleRowClick"
      >
        <el-table-column prop="gmtCreated" label="开始时间"></el-table-column>
        <el-table-column prop="caller" label="发起人"></el-table-column>
        <el-table-column prop="duration" label="会话时长"></el-table-column>
        <el-table-column
          prop="attendeeCount"
          label="会话人数"
        ></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i
              class="el-icon-edit-outline"
              @click.stop="editList(scope.row)"
            ></i>
            <i class="el-icon-delete" @click.stop="deleteList(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination
      v-model="pageOptions"
      @curPageChange="handleCurPageChange"
    ></common-pagination>
    <his-record-search
      v-model="searchDialogVisible"
      @handleSearch="handleSearch"
    ></his-record-search>
    <edit-desc
      v-model="editDescDialog"
      :currentItem="currentItem"
      @handleItem="handleEdit"
    ></edit-desc>
    <common-delete
      v-model="deleteDialogVisible"
      @handleDelete="handleDelete"
    ></common-delete>
  </div>
</template>

<script>
import CommonPagination from "@/components/CommonPagination";
import HisRecordSearch from "@/components/HisRecordSearch";
import EditDesc from "@/components/EditDesc";
import CommonDelete from "@/components/CommonDelete";
import { mapGetters } from "vuex";

export default {
  name: "",
  components: {
    CommonPagination,
    HisRecordSearch,
    EditDesc,
    CommonDelete,
  },
  data() {
    return {
      tableData: [],
      searchDialogVisible: false,
      editDescDialog: false,
      deleteDialogVisible: false,
      options: {
        caller: "",
        gmtCreated: "",
        gmtUpdated: "",
        remark: "",
        mine: 0,
      },
      pageOptions: {
        page: 1,
        perPage: 20,
        total: 0,
      },
      currentItem: "",
    };
  },
  computed: {
    ...mapGetters(["getTempraryShow"]),
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      this.$axios({
        method: "get",
        url: "api/csp/rc/v1/session/page",
        params: {
          caller: this.options.caller,
          gmtCreated: this.options.gmtCreated,
          gmtUpdated: this.options.gmtUpdated,
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage,
          remark: this.options.remark,
          mine: this.options.mine,
        },
      }).then((res) => {
        const data = res.data;
        if (!data.status) {
          this.tableData = data.data.rows;
          this.pageOptions.total = data.data.total;
        }
      });
    },
    // 分页
    handleCurPageChange() {
      this.getList();
    },
    search() {
      this.searchDialogVisible = true;
    },
    handleSearch(params) {
      this.pageOptions.page = 1;
      this.searchDialogVisible = false;
      this.options.caller = params.caller;
      this.options.remark = params.remark;
      this.options.gmtCreated = params.startDate;
      this.options.gmtUpdated = params.endDate;
      this.getList();
    },
    // 查询类型改变
    typeChange() {
      this.pageOptions.page = 1;
      this.getList();
    },
    // 列表编辑
    editList(item) {
      this.currentItem = this.$Utils.deepCopy(item);
      this.editDescDialog = true;
    },
    handleEdit(params) {
      this.$axios({
        method: "put",
        url: `api/csp/rc/v1/session/${params.id}`,
        data: {
          data: {
            remark: params.remark,
          },
        },
      }).then((res) => {
        const data = res.data;
        if (!data.status) {
          this.editDescDialog = false;
          this.$message({
            type: "success",
            message: "编辑成功！",
            duration: 1000,
          });
          this.pageOptions.page = 1;
          this.getList();
        }
      });
    },
    deleteList(item) {
      this.currentItem = item;
      this.deleteDialogVisible = true;
    },
    handleDelete() {
      this.$axios({
        method: "delete",
        url: `api/csp/rc/v1/session/${this.currentItem.id}`,
      }).then((res) => {
        const data = res.data;
        if (!data.status) {
          this.deleteDialogVisible = false;
          this.$message({
            type: "success",
            message: "删除成功！",
            duration: 1000,
          });
          this.pageOptions.page = 1;
          this.getList();
        }
      });
    },
    handleRowClick(row) {
      this.$router.push({
        path: `/remoteCollaboration/historicalRecords/details`,
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
