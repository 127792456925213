<template>
  <el-dialog class="js-dialog-form" title="高级搜索" :visible.sync="dialogVisible" :close-on-click-modal="false" width="560px">
    <ul>
      <!-- <li class="label">开始时间</li> -->
      <li>
        <div class="label common-tip"><i>*</i>开始时间</div>
        <date-range v-model="dateRange"></date-range>
      </li>
      <!-- <li class="label">发起人</li> -->
      <li>
        <div class="label common-tip"><i>*</i>发起人</div>
        <el-input v-model="caller" placeholder="请输入发起人" size="small"></el-input>
      </li>
      <!-- <li class="label">备注</li> -->
      <li>
        <div class="label common-tip"><i>*</i>备注</div>
        <el-input v-model="remark" type="textarea" :rows="4" placeholder="请输入备注关键字" size="small"></el-input>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleConfirm" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DateRange from '@/components/DateRange'
export default {
  name: '',
  props: ['value'],
  components: {
    DateRange
  },
  data() {
    return {
      dateRange: ['',''],
      caller: '',
      remark: ''
    }
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {},
  methods: {
    handleCancel(){
      this.dateRange = ['','']
      this.caller = ''
      this.remark = ''
      this.dialogVisible = false
    },
    handleConfirm(){
      let params = {
        startDate: this.dateRange[0],
        endDate: this.dateRange[1],
        caller: this.caller,
        remark: this.remark
      }
      this.$emit('handleSearch',params)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>